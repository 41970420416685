<template>
  <div class="kt-container">
    <v-row v-if="!permission">
      <b-col xs="12">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>
    </v-row>

    <AlertExpirePassword />

    <v-row v-if="permission">
      <b-col xs="12">
        <b-card class="h-100">
          <template v-slot:header>
            <div class="d-flex align-items-center">
              <b-row>
                <b-col
                  sm="12"
                  md="2"
                  class="align-middle align-items-center m-auto text-md-left text-center"
                  justify="center"
                >
                  {{ totalRows }}
                  {{ $tc("INVOICES.LIST") }}
                </b-col>

                <b-col
                  sm="12"
                  md="10"
                  class="align-middle align-items-center m-auto text-center"
                  justify="center"
                >
                  <v-text-field
                    v-model="searchFilter"
                    hide-details
                    :label="$tc('INVOICES.SEARCH')"
                    prepend-inner-icon="search"
                    solo
                    filled
                    color="blue"
                    dense
                    clearable
                    @input="inputSearchHandler"
                  ></v-text-field>
                </b-col>
              </b-row>
            </div>
          </template>

          <Datatable :items="items" :loading="isLoading" @download="download" />

          <template v-slot:footer>
            <v-row justify="center" class="mt-1">
              <b-pagination
                class="pt-0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="filters.limit"
                @next="changePage"
                @previous="changePage"
              ></b-pagination>
            </v-row>
          </template>
        </b-card>
      </b-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Datatable from "@/components/invoices/datatable";
import AlertExpirePassword from "@/components/AlertExpirePassword";

import { getInvoices, dowloadInvoice } from "@/api/invoices/getters";

export default {
  name: "Invoice",

  components: {
    AlertExpirePassword,
    Datatable
  },

  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      isLoading: false,
      searchFilter: "",
      timeoutSearch: 0,
      filters: {
        q: "",
        limit: 30,
        page: 1
      }
    };
  },

  computed: {
    ...mapGetters(["currentUser", "isAdmin"]),
    permission() {
      if (this.isAdmin) {
        return true;
      } else if (this.currentUser.company) {
        return true;
      }
      return false;
    }
  },

  mounted() {
    this.load();
  },

  methods: {
    changePage() {
      this.filters.page = this.currentPage;
      this.filters.q = this.searchFilter;
      this.load();
    },

    inputSearchHandler(text) {
      if (text && text.length > 2) {
        this.searchFilter = text.toLowerCase();

        clearTimeout(this.timeoutSearch);

        this.timeoutSearch = setTimeout(() => {
          this.filters.q = this.searchFilter;
          this.load();
        }, 1000);
      }
      if (!text) {
        this.searchFilter = "";
        this.filters.q = this.searchFilter;
        this.load();
      }
      this.currentPage = 1;
      this.filters.page = 1;
    },

    async load() {
      this.isLoading = true;
      this.items = [];

      try {
        const response = await getInvoices(this.axios, this.filters);
        this.items = response.data || [];
        this.totalRows = response.total_items;
      } catch (error) {
        console.error("---ERROR-INVOCIES---");
        console.error(error);

        this.totalRows = 0;
      }

      this.isLoading = false;
    },

    download(item) {
      dowloadInvoice(item.id, this.axios, {})
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${item.name}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(e => {
          console.error(e);

          this.$bvToast.toast(`Download failed!`, {
            title: `Download`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        });
    }
  },
  watch: {
    currentPage() {
      this.changePage();
    }
  }
};
</script>
