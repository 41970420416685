import { get, getBlob } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api2.0";

export const getInvoices = (axios, filters = null) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.INVOICES.path
    },
    filters,
    axios
  );

export const dowloadInvoice = (id, axios, filters = null) =>
  getBlob(
    {
      path: ENTITY_NAME_IN_URI.INVOICES.path,
      ressources: [
        {
          name: id,
          value: ENTITY_NAME_IN_URI.INVOICES.DOWNLOAD.path
        }
      ]
    },
    filters,
    axios
  );
