<template>
  <b-table
    :fields="getHeader"
    :items="getItems"
    :busy="loading"
    :items-per-page="50"
    responsive
    show-empty
    ref="table"
  >
    <template #empty>
      <div class="d-flex justify-content-center"></div>
    </template>

    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </template>

    <template #cell(company)="data">
      {{ data.item.company ? data.item.company.name : "-" }}
    </template>

    <template #cell(name)="data">
      {{ data.item.name }}
    </template>

    <template #cell(createdAt)="data">
      {{ $d(new Date(data.item.createdAt), "short") }}
    </template>

    <template #cell(year)="data">{{ data.item.year }}</template>
    <template #cell(month)="data">{{ data.item.month }}</template>

    <template #cell(volume)="data">{{ data.item.volume }}</template>
    <template #cell(vat)="data">{{ data.item.vat }}</template>
    <template #cell(amount)="data"
      >{{ data.item.amount }}
      {{
        data.item.company && data.item.company.currency != ""
          ? data.item.company.currency
          : "€"
      }}</template
    >

    <template #cell(actions)="data">
      <b-dropdown text="Actions">
        <b-dropdown-item @click="download(data.item)"
          ><v-icon color="green">mdi-download</v-icon
          >{{ $tc("INVOICES.MENU_ACTIONS.DOWNLOAD") }}</b-dropdown-item
        >
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { headers } from "@/components/invoices/config";

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      currentItems: [],
      itemsLine: [],
      isLoading: false
    };
  },

  computed: {
    getHeader() {
      return headers;
    },

    getItems() {
      let items = [...this.items];

      items.forEach(element => {
        if (element.minDate && element.maxDate) {
          this.setStatusForCampaign(element);
        }
      });

      return items;
    }
  },

  methods: {
    download(item) {
      this.$emit("download", item);
    }
  }
};
</script>

<style>
.bb-row-details {
  background: #f7f7f7;
  border-radius: 10px;
}

.dropdown-menu {
  padding-left: 0 !important;
}
</style>
