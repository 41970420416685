export const headers = [
  { label: "Company", key: "company", sortable: true },
  { label: "Name", key: "name", sortable: true },
  { label: "Generate At", key: "createdAt", sortable: true },
  { label: "Year", key: "year", sortable: true },
  { label: "month", key: "month", sortable: true },
  { label: "volume", key: "volume", sortable: true },
  { label: "vat", key: "vat", sortable: false },
  { label: "amount", key: "amount", sortable: true },
  { label: "Actions", key: "actions", sortable: false }
];
